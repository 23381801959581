// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/geng/projects/geng.io/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("/Users/geng/projects/geng.io/src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-besides-work-js": () => import("/Users/geng/projects/geng.io/src/pages/besides-work.js" /* webpackChunkName: "component---src-pages-besides-work-js" */),
  "component---src-pages-index-js": () => import("/Users/geng/projects/geng.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("/Users/geng/projects/geng.io/src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */)
}

