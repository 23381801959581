module.exports = [{
      plugin: require('/Users/geng/projects/geng.io/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Geng's personal website","short_name":"geng.io","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon-32x32.png"},
    },{
      plugin: require('/Users/geng/projects/geng.io/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Noto Sans"]}},
    },{
      plugin: require('/Users/geng/projects/geng.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
